body {
    background-color: #f3f3f9;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
}

*,
 ::after,
 ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

td {
    border-width: 0;
    padding: 1rem 1rem;
    border-width: 0;
}

table {
    background-color: #fff;
    width: 96%;
    margin: 2%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

input {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #000;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.9rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    margin-bottom: 25px;
}

input::file-selector-button {
    background-color: #eff2f7;
    font-weight: 400;
    color: #000;
    padding: 0.5rem 0.9rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

label {
    display: block;
    margin-bottom: 10px;
}

select {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #000;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.6rem 0.9rem;
    width: 100%;
    margin-bottom: 25px;
}
.input_group select {
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 80%;
    float: left;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.66rem 0.9rem;
}

tr:nth-of-type(odd)>* {
    background-color: #f6f6f6;
}

.bn_success {
    background-color: #0ab39c;
    border-color: #0ab39c;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.bn_success:hover {
    background-color: #fff;
    color: #0ab39c;
}

.head_tr td {
    font-weight: 600;
    background-color: #fff;
}

.page_title {
    padding: 1rem 2rem;
    background: #fff;
    font-size: 17px;
    font-weight: 600;
    border: 0 solid rgba(0, 0, 0, .125);
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.pencil {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.pencil a {
    color: #0ab39c;
}

.trash {
    color: #f06548;
    display: inline-block;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.pencil:hover,
.trash:hover {
    opacity: 0.5;
}

#navbar_menu {
    background-color: #405189;
    width: 15%;
    color: #abb9e8;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    transition: all 0.2s ease-out;
}

#root.enter #navbar_menu {
    width: 15%;
}

#root.leave #navbar_menu {
    width: 5%;
}

#navbar_menu h2 {
    color: #f3f3f9;
    margin-left: 25px;
    font-size: 15px;
    transition: all 0.5s ease-out;
}

#navbar_menu .menu_item {
    color: #abb9e8;
    font-size: .9375rem;
    margin: 25px;
}

#navbar_menu .menu_item a {
    color: #abb9e8;
    text-decoration: none;
    transition: all 0.5s ease-out;
}

#navbar_menu .menu_item a:hover {
    color: #fff;
}

#navbar_menu .menu_item .icon_menu {
    margin-right: 15px;
}

#root.leave #navbar_menu .menu_item .icon_menu {
    margin: 0;
}

#root.leave #navbar_menu .text_menu,
#root.leave #navbar_menu h2,
#root.leave #navbar_menu .logo_menu {
    display: none;
}

#root.leave #navbar_menu .menu_item {
    font-size: 1.3rem;
    margin: 30px 0 0 0;
    text-align: center;
}

.logo_menu {
    filter: brightness(0) invert(1);
    margin: 25px auto 40px auto;
    width: 150px;
    height: auto;
    display: block;
}

#login {
    background-color: #fff;
    width: 500px;
    height: 500px;
    margin: 0 auto;
    padding: 3%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -320px;
}

.error {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    color: red;
}

.logo_login {
    text-align: center;
    margin-bottom: 20px;
}

.recover {
    text-align: center;
}

.recover a {
    color: #000;
    text-decoration: none;
}

.recover a:hover {
    color: #0ab39c;
    transition: all 0.3s ease-out;
}

#header {
    background-color: #fff;
    margin: 0 auto;
    height: 70px;
    padding: 0 1.5rem 0 calc(1.5rem / 2);
    border-bottom: solid 1px #f3f3f3;
}

#content_top {
    width: 100%;
}

#menu_icon {
    font-size: 24px;
    line-height: 70px;
    padding-left: 20px;
    color: #405189;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s ease-out;
}

#menu_icon:hover {
    color: #abb9e8;
}

.container {
    width: 85%;
    float: right;
    transition: all 0.2s ease-out;
}

#content_top {
    width: 85%;
    float: right;
    transition: all 0.2s ease-out;
}

#arrow {
    display: none;
}

#root.enter #hamburger {
    display: inline-block;
    left: -15%;
}

#root.leave #hamburger {
    display: none;
    left: 0%;
}

#root.leave #arrow {
    display: inline-block;
    left: -15%;
}

#root.enter #arrow {
    display: none;
    left: 0%;
}

#root.leave #content_top,
#root.leave .container {
    width: 95%;
}

#root.enter #content_top,
#root.enter .container {
    left: 85%;
}

.content_left {
    background-color: #fff;
    width: 70%;
    margin: 2% 1% 2% 2%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    float: left;
}

.content_right {
    background-color: #fff;
    width: 24%;
    margin: 2% 2% 2% 1%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    float: left;
}

.input_group_text {
    width: 20%;
    float: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 0.9rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    white-space: nowrap;
    background-color: #eff2f7;
    border: 1px solid #ced4da;
    border-radius: 0.25rem 0 0 0.25rem;
}

.input_group input {
    width: 80%;
    float: left;
    border-radius: 0 0.25rem 0.25rem 0;
}

.input_group textarea {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #000;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.9rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    margin-bottom: 25px;
    height: 200px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0.9rem;
    font-size: .8125rem;
    border-radius: 0.25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.btn_primary {
    font-weight: 600;
    color: #fff;
    background-color: #405189;
    border-color: #405189;
    transition: all 0.5s ease-out;
}

.btn_primary:hover {
    background-color: #838fb9;
    border-color: #838fb9;
}

#new_content {
    margin: 2% 0 0 2%;
}

#new_content a {
    display: block;
    color: #fff;
    text-decoration: none;
}

.content_right label {
    font-weight: 600;
}

.readonly {
    background-color: #f6f6f6;
    color: #888;
}

.switch {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    margin-bottom: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 0.25rem;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 0.25rem;
}
form {
    position: relative;
}
input:checked+.slider {
    background-color: #405189;
}

input:focus+.slider {
    box-shadow: 0 0 1px #405189;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

input.color {
    height: 35px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border: none;
}

.left_content_taxonomy {
    float: left;
    background-color: #fff;
    width: 26%;
    margin: 2% 0 2% 2%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.right_content_taxonomy {
    float: left;
    background-color: #fff;
    width: 68%;
    margin: 2%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.right_content_taxonomy table {
    border: none;
    box-shadow: none;
}

.taxonomy {
    background-color: #fff;
    width: 50%;
    margin: 2%;
    padding: 2%;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

#message_post {
    font-weight: 400;
    font-size: 16px;
    margin: 15px 0 15px 0;
}

.img_preview {
    text-align: center;
    margin: 2% 15%;
    width: 70%;
}

.img_preview img {
    width: 100%;
}
.addtaxonomy .img_preview {
    text-align: center;
    margin: 2% 0;
    width: 40%;
}
.delete_img {
    font-size: 16px;
    color: #405189;
    transition: all 0.5s ease-out;
    cursor: pointer;
    margin: 10px;
}
.delete_img:hover {
    color: #abb9e8;
    transition: all 0.5s ease-out;
}
.color_div {
    position: relative;
}
.show_color {
    height: 14px;
    width: 100%;
    margin-top: -5px;
}
.container_color {
    position: absolute;
    height: 35px;
    appearance: none;
    background-clip: padding-box;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    padding: 2%;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    margin-bottom: 25px;
}
#logout {
    font-size: 20px;
    color: #405189;
    position: absolute;
    right: 0;
    top: 0;
    margin: 19px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}
#logout:hover {
    color: #abb9e8;
    transition: all 0.2s ease-out;
}
.select_multiple {
    margin-bottom: 20px;
}

@media (min-width: 1500px) {
    #root.leave #navbar_menu {
        width: 3%;
    }
    #root.leave #content_top,
    #root.leave .container {
        width: 97%;
    }
}